export default {
  label: "Benutzerverwaltung",
  userAuthorization: "User Authorization",
  tabs: {
    pendingUserRequests: "Ausstehende Benutzeranfragen",
    userAndRolesAssignment: "Benutzer- und Rollenzuweisung",
  },
  assignRolesToUser: {
    label: "Rollen zu Benutzer Zuweisung",
  },
  inviteUsers: {
    label: "Benutzer einladen",
    formFields: {
      firstName: "Vorname",
      lastName: "Nachname",
      emailAdress: "Email-Adresse",
    },
  },
  userRoleManager: {
    inviteUser: "Benutzer einladen",
    label: "Benutzer-Rollenmanager",
    roleName: "Rollenname",
    selectRole: "Select Role",
    assignedTo: "Zugewiesen an",
    noUsersAssignedMessage: "Es gibt noch keine Benutzer, die dieser Rolle zugeordnet sind.",
    permissions: "Berechtigungen",
    createdUserRole: "Ihre Benutzerrolle wurde erfolgreich erstellt.",
    useRoleCreate: "Benutzerrolle erstellt",
    discardChanges: "Änderungen verwerfen",
    discardMessage:
      "Sie haben Änderungen vorgenommen, die nicht gespeichert sind. Sind Sie sicher, dass Sie diese Seite verlassen und die Änderungen ungespeichert lassen wollen?",
  },
  button: {
    create: {
      role: "Neue Rolle",
    },
  },
  form: {
    label: {
      role: "Rolle bearbeiten",
      allUsers: "Alle Benutzer",
      user: "Benutzer bearbeiten",
      deleteUserRole: "Benutzerrolle löschen",
      benutzerApprove: "Benutzer genehmigen",
      benutzerDeny: "Benutzer ablehnen",
      editRole: "Rolle bearbeiten",
      tenantDeletionFailed: "Organisationlöschung fehlgeschlagen",
      tenantDeleteSuccess: "Organisation erfolgreich gelöscht",
      deleteTenant: "Organisation löschen",
      checkTenantRole: "Organisationsrolle prüfen",
    },
    message: {
      continueDeleteMessage: "Sind Sie sicher, dass Sie weitermachen wollen?",
      approveMessage: "Sind Sie sicher, dass Sie den Benutzer genehmigen wollen?",
      denyMessage: "Sind Sie sicher, dass Sie den Benutzer ablehnen wollen?",
      userRoleDeleteMessagePt1: "Diese Benutzerrolle ist derzeit zugewiesen an",
      userRoleDeleteMessagePt2: "Sie werden keinen Zugang mehr zu bestimmten App-Bereichen haben.",
      userErrorMessage: "der Benutzer darf nicht leer sein.",
      userNameErrorMessage: "Der Benutzername darf nicht leer sein.",
      userLastNameErrorMessage: "Der Benutzer Nachname darf nicht leer sein.",
      phoneNumerErrorMessage: "Der eingegebene Wert muss eine Telefonnummer sein.",
      streetAndNumberErrorMessage:
        "Bitte geben Sie den Straßennamen gefolgt von einem Komma und der Hausnummer ein (z.B. Hauptstraße, 123).",
      postalCodeErrorMessage:
        "Bitte geben Sie eine gültige Postleitzahl ein. In Deutschland besteht die Postleitzahl aus fünf Ziffern.",
      requiredInformation: "Diese Information ist erforderlich.",
      tenantNameExists: "Der Name der Organisation existiert bereits, bitte geben Sie einen anderen Namen ein.",
      userBlockMessage: "Sind Sie sich sicher dass Sie diesen Benutzer blockieren möchten?",
      userUnblockMessage: "Sind Sie sich sicher dass Sie diesen Benutzer entsperren möchten?",
      deleteTenantNotPossible: "Organisation darf nicht gelöscht werden, da noch Benutzer zugewiesen sind",
      deleteTenantSuccess: "Die alte Organisation wurde erfolgreich gelöscht.",
      tenantCanBeDeleted: ({ tenantName }: { tenantName: string | undefined }) =>
        `Der Organisation ${
          tenantName && `"${tenantName}" `
        }sind keine Benutzer mehr zugeordnet. Möchten Sie diese Organisation aus der Datenbank entfernen?`,
      tenantCanNotBeDeleted: ({ tenantName }: { tenantName: string | undefined }) =>
        `Die Organisation ${
          tenantName && `"${tenantName}" `
        }kann derzeit nicht gelöscht werden, da dieser Organisation momentan noch weitere Benutzer zugeordnet sind. Weisen sie zuvor alle verbliebenen Benutzer einer neuen Organisation zu, um ${
          tenantName ? `"${tenantName}"` : "sie"
        } aus der Datenbank entfernen zu können.`,
        checkTenantRole: ({ currentTenantRole, newTenantRole }: { currentTenantRole: string | undefined, newTenantRole:string | undefined  }) =>
        `<p> Die aktuelle Rolle des Benutzers stimmt nicht mit der Standardrolle des ausgewählten Mandanten überein. Bitte beachten Sie, dass die folgenden Änderungen ausgeführt werden, wenn Sie fortfahren.</p>
        <ul>
        <li>Entfernen der vorhandenen Rollen-Zuweisungen: ${currentTenantRole ? `"${currentTenantRole}".` : "Keine"}</li>  
        <li>Neue Rollen-Zuweisung: ${newTenantRole ? `"${newTenantRole}".` : "Keine"}</li>
        </ul>
        <p>Möchten Sie fortfahren?</p>`,
    },      
    edit: {
      tabLabel: {
        editRole: "Rolle ändern",
        assignScope: "Berechtigungen zuordnen",
        assignUser: "Benutzer zuordnen",
      },
      listLabel: {
        assigned: "Zugeordnet",
        unassigned: "Verfügbar",
      },
    },
  },
  table: {
    column: {
      description: "Beschreibung",
      email: "Email",
      firstName: "Vorname",
      lastName: "Nachname",
      name: "Name",
      role: "Rollen des Benutzers",
      tenant: "Organisation / Unternehmen",
      value: "Wert",
      userRole: "Name der Benutzerrolle",
      roleDescription: "Beschreibung der Rolle",
      pendingRequestsAction: "Aktionen",
    },
  },
};
