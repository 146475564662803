import { getMsalUser } from "authentication";
import { execGetUserInfoQuery } from "graphqlBase/userManagement/user/__generated__/getUserInfo";

import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userInfoAtom } from "./state";
import { SimpleScope, UserInfo } from "./types";

const noScopes = [] as SimpleScope[];

/**
 * Retrieves the user information with optional modifications.
 * @param args - Optional partial UserInfo object to modify the user information.
 * @returns The modified UserInfo object or undefined if the user is not available.
 */

export const useCreaetUserInfo = (isAuthenticated: boolean) => {
  const setUserInfo = useSetRecoilState(userInfoAtom);

  useEffect(() => {
    getUserWithScopes().then((res) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (!res.tenantId) return;
      setUserInfo(res as UserInfo);
    });
  }, [setUserInfo, isAuthenticated]);
};

export const getUserWithScopes = async () => {
  const user = getMsalUser();
  if (!user) return { scopes: noScopes };
  const email = user.idTokenClaims?.email as string;
  const res = await execGetUserInfoQuery({ variables: { email } });

  const data = res.data;
  if (!data) return { scopes: noScopes };
  const userInfo = data.users?.items?.[0];
  const role = userInfo?.tenant?.tenantRole;
  const scopes =
    userInfo?.userBridgeRoles.flatMap<SimpleScope>(({ role }) =>
      role?.roleBridgeScopes.map<SimpleScope>(({ scope }) => scope)
    ) ?? [];
  const tenantId = userInfo?.tenantId;
  if (!role || !tenantId) return { scopes: noScopes };
  return {
    ...user,
    tenantId,
    id: userInfo.id,
    role: role,
    scopes,
    email,
  };
};

export const useGetUserInfo = (): UserInfo | undefined => {
  const userInfo = useRecoilValue(userInfoAtom);
  return userInfo;
};

/**
 * Retrieves the roles of the current user.
 * @returns An array of strings representing the user roles.
 */
export const useGetUserRoles = (): string[] => {
  const user = useGetUserInfo();
  if (!user) return [];
  return user.idTokenClaims?.roles ?? [];
};

export const useGetUserScopes = (): string[] => {
  const user = useGetUserInfo();
  if (!user) return [];
  return user.scopes.map((scope) => scope.value);
};
