export default {
  assets: {
    asset: "Anlage",
    assets: "Anlagen",
    assetLevel: "Anlagenstufe",
    assetModel: "Anlagenmodell",
    model: "Modell",
    models: "Modelle",
    assetType: "Anlagentyp",
    assetTypes: "Anlagentypen",
    provider: "Anbieter",
    serialNo: "Seriennummer",
    tags: "Tags",
    user: "Benutzer",
    itemManufacturer: "Hersteller",
    assetId: "Anlage Id",
    AssetLocalization: "Anlage Lokalisierung",
    identifier: "Kennung",
    createOrSelectTags: "oder auswählen",
    createNewAssetTag: "Neues Tag erstellen",
    errorAssetTag: "Tag existiert bereits",
    noAssets: "Keine Anlagen",
  },
  user: {
    name: "Benutzer",
    plural: "Benutzer",
    columns: {
      id: "ID",
      userName: "Benutzername",
      myProfile: "Mein Profil",
      firstName: "Vorname",
      lastName: "Nachname",
      roles: "Rollen",
      registrationDate: "Registrierungsdatum",
      tenant: "Organisation",
      authenticationProvider: "Authentication Provider",
      contact: "Kontakt",
      contactList: "Kontaktliste",
      status: "Status",
      approved: "Genehmigt",
      pending: "Ausstehend",
      email: "Email",
      language: "Sprache",
      userDescription: "Benutzerbeschreibung",
      lastLogin: "Last Login",
      createdAt: "Erstellt am",
      modifiedAt: "Geändert am",
      isDeleted: "is deleted",
      displayName: "Display Name",
      declined: "Abgelehnt",
    },
  },
  tenant: {
    name: "Organisation",
    plural: "Organisationen",
    phone: "Telefonnummer",
    zipCode: "Postleitzahl",
    country: "Land",
    city: "Stadt",
    streetAndNumber: "Straße und Nr.",

    columns: {
      id: "ID",
      tenantIdentifier: "Organisation ID",
      tenantName: "Name der Organisation",
      isDeleted: "is deleted",
      createdAt: "Erstellt am",
      modifiedAt: "Geändert am",
      tenantRole: "Rolle der Organisation",
      tenantType: "Typ der Organisation",
    },
  },
  role: {
    name: "Rolle",
    plural: "Rollen",
    tenantRole: {
      "2ab640dd-39c0-4dc5-9a3f-1919f8efd4d0": "Anlagenbetreiber",
      "6413f36a-0dd6-42a5-a3ac-a99f4539f48e": "Rettungsstelle",
      "f8aa95db-158e-4a9e-8a0f-b5dcbcc5b1b7": "Platformbetreiber",
    },
    columns: {
      id: "ID",
      name: "Name",
      description: "Beschreibung",
      isDeleted: "is deleted",
      createdAt: "Erstellt am",
      modifiedAt: "Geändert am",
      isDefaultRole: "ist Standardrolle",
    },
  },
};
