export default {
  label: "User Management",
  userAuthorization: "User Authorization",
  tabs: {
    pendingUserRequests: "Pending User Requests",
    userAndRolesAssignment: "User and Roles Assignment",
  },
  assignRolesToUser: {
    label: "Roles To User Assignment",
  },
  inviteUsers: {
    label: "Invite Users",
    formFields: {
      firstName: "First Name",
      lastName: "Last Name",
      emailAdress: "Email Address",
    },
  },
  userRoleManager: {
    inviteUser: "Invite User",
    label: "User Role Manager",
    roleName: "Role Name",
    selectRole: "Select Role",
    assignedTo: "Assigned to",
    noUsersAssignedMessage: "There are no users assigned to this role yet.",
    permissions: "Permissions",
    createdUserRole: "Your user role has been succesfully created.",
    useRoleCreate: "User role created",
    discardChanges: "Discard changes",
    discardMessage:
      "You've commited changes that are not saved. Are you sure you want to quit this page and leave those changes unsaved? ",
  },
  button: {
    create: {
      role: "New role",
    },
  },
  form: {
    label: {
      role: "Edit Role",
      allUsers: "All Users",
      user: "Edit User",
      deleteUserRole: "Delete user role",
      benutzerApprove: "Approve user",
      benutzerDeny: "Deny user",
      editRole: "Edit role",
      tenantDeletionFailed: "Tenant deletion failed",
      tenantDeleteSuccess: "Tenant successfully deleted",
      checkTenantRole: "Check Tenant Role",
      deleteTenant: "Delete Tenant",
    },
    message: {
      continueDeleteMessage: "Are you sure you want to continue?",
      approveMessage: "Are you sure you want to approve the user?",
      denyMessage: "Are you sure you want to deny the user?",
      userRoleDeleteMessagePt1: "This user role is currently assignet to",
      userRoleDeleteMessagePt2: "They will no longer have access to certain app areas.",
      userErrorMessage: "the display name should not be empty.",
      userNameErrorMessage: "The user name should not be empty.",
      userLastNameErrorMessage: "The user last name should not be empty.",
      phoneNumerErrorMessage: "The entered value must be a phone number.",
      streetAndNumberErrorMessage:
        "Please enter the street name followed by a comma and the house number (e.g. Hauptstraße, 123).",
      postalCodeErrorMessage: "Please enter a valid postal code. In Germany, the postal code consists of five digits.",
      requiredInformation: "This information is required.",
      tenantNameExists: "The name of the Tenant already exists, please enter another name.",
      userBlockMessage: "Are you sure you want to block this user?",
      userUnblockMessage: "Are you sure you want to unblock this user?",     
      deleteTenantNotPossible: "Tenant must not be deleted since there are still remaining users assigned",
      deleteTenantSuccess: "The old Tenant was successfully deleted.",
      tenantCanBeDeleted: ({ tenantName }: { tenantName: string | undefined }) =>
        `There are no longer any users associated with the orgnization${
          tenantName && ` "${tenantName}"`
        }. Do you want to remove this organization from the database?`,
      tenantCanNotBeDeleted: ({ tenantName }: { tenantName: string | undefined }) =>
        `The organization ${
          tenantName && `"${tenantName}" `
        }cannot currently be deleted because other users are currently assigned to this organization. Beforehand, assign all remaining users to a new organization to be able to remove ${
          tenantName ? `"${tenantName}"` : "it"
        } from the database.`,
        checkTenantRole: ({ currentTenantRole, newTenantRole }: { currentTenantRole: string | undefined, newTenantRole: string | undefined }) =>
          `<p>The user's current role does not align with the default role of the selected tenant. Please note that the following changes will be executed if you proceed:</p>
          <ul>
            <li>Removal of existing role assignments: ${currentTenantRole ? `"${currentTenantRole}".` : "None"}</li>
            <li>New role assignment: ${newTenantRole ? `"${newTenantRole}".` : "None"}</li>
          </ul>
          <p>Do you wish to continue?</p>`,        
    },
    edit: {
      tabLabel: {
        editRole: "Change Role",
        assignScope: "Assign Permissions",
        assignUser: "Assign Users",
      },
      listLabel: {
        assigned: "Assigned",
        unassigned: "Unassigned",
      },
    },
  },
  table: {
    column: {
      description: "Description",
      email: "Email",
      firstName: "First Name",
      lastName: "Last Name",
      name: "Name",
      role: "Roles of the User",
      tenant: "Tenant / Company",
      value: "Value",
      userRole: "User role name",
      roleDescription: "Role description",
      pendingRequestsAction: "Action",
    },
  },
};
