export default {
  addApp: "App hinzufügen",
  addButton: "Anlage hinzufügen",
  addBillingAddress: "Rechnungsadresse hinzufügen",
  addModelButton: "Modell hinzufügen",
  columnOptions: "Spaltenoptionen",
  delete: "löschen",
  density: "Dichte",
  compact: "Kompakt",
  standard: "Standard",
  comfortable: "Komfortabel",
  edit: "bearbeiten",
  filter: "Filter",
  checkboxSelection: "Checkbox-Auswahl",
  importAssets: "Anlagen importieren",
  exportAllContacts: "Alle Kontakte exportieren",
  exportAllAssets: "Alle Anlagen exportieren",
  removeApp: "App entfernen",
  rowsPerPage: "Zeilen pro Seite",
  search: "Suche",
  filterValue: "Filterwert",
  searchTable: "Durchsuche Tabelle",
  serialNo: "Seriennummer",
  findColumn: "Spalte finden",
  sort: "Sortierung",
  tags: "Tags",
  gotIt: "verstanden",
  deleteNotPossible: "Löschen nicht möglich",
  editUser: "Benutzer bearbeiten",
  blockUser: "Benutzer blockieren",
  unblockUser: "Benutzer entsperren",
  editTenant: "Organisation bearbeiten",
  noData: "Keine Daten verfügbar",
};
